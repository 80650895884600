import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from "firebase/app";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyDmYzMORoVvFedaJUUxflPsIPN3bs8DmMY",
    authDomain: "socialgames-ff9fe.firebaseapp.com",
    databaseURL: "https://socialgames-ff9fe.firebaseio.com",
    projectId: "socialgames-ff9fe",
    storageBucket: "socialgames-ff9fe.appspot.com",
    messagingSenderId: "783406154050",
    appId: "1:783406154050:web:58330416d0590ebedb48db",
  //  measurementId: "G-NSW4KWL37X"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
//firebase.analytics();


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
