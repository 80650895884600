import React from 'react';
import './App.css';
import Button from '@material-ui/core/Button/Button';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import {
    AppBar,
    Avatar,
    Card,
    Chip,
    Dialog, DialogActions, DialogContent, DialogContentText,
    DialogTitle,
    Icon,
    Modal,
    TextField,
    Toolbar, Tooltip,
    Typography
} from '@material-ui/core';
import IconButton from "@material-ui/core/IconButton";
import Countdown from "react-countdown";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const FirebaseUserContext = React.createContext<firebase.User|null>(null);
const AppUserContext = React.createContext<AppUser|null>(null);


class UserService {

    static setOnUserChange(uid:string, callback:Function){ //todo better types and is this correct way?
        firebase.firestore().collection('users').doc(uid).onSnapshot((doc)=>{
            var source = doc.metadata.hasPendingWrites ? "Local" : "Server";
            console.log("setOnUserChange", source, " data: ", doc.data());
            callback(doc);
        });
    }

    static async findUserByEmail(email:string):Promise<AppUser|null>{
        const queryResult:firebase.firestore.QuerySnapshot =  await firebase.firestore().collection('users').where("email", "==" , email).get();
        if(queryResult.size == 1 ){
            const user = new AppUser(queryResult.docs[0].data());
            return user;
        }
        return null;
    }


    static async setRoomIdToUser(userUid: string, roomId: string) {
        console.log("setting roomid:", roomId);

        const userRef = firebase.firestore().collection('users').doc(userUid);

        return firebase.firestore().runTransaction(async (transaction) => {
            const userSnapshot = await transaction.get(userRef)
            if (userSnapshot.exists) {
                let user = new AppUser(userSnapshot.data());
                user.roomId = roomId;
                transaction.set(userRef, user.toPlain(), {merge: true});
            }
        })
    }


    static async removeUserFromRoom(userToLeaveUid: string|undefined, roomId:string|undefined|null) {
        if (userToLeaveUid == null || roomId == null) {
            return
        }
        const roomRef = firebase.firestore().collection('rooms').doc(roomId);
        const userRef = firebase.firestore().collection('users').doc(userToLeaveUid);

        await firebase.firestore().runTransaction(async (transaction) => {
            const [userSnapshot, roomSnapshot] = await Promise.all([transaction.get(userRef), transaction.get(roomRef)]);
            if (userSnapshot.exists) {
                const appUser = new AppUser(userSnapshot.data());

                if (appUser.roomId == roomId) {
                    appUser.roomId = null;
                    transaction.update(userRef, appUser.toPlain());
                }
            }
            if (roomSnapshot.exists) {
                const roomDTO = new RoomDTO(roomSnapshot.data());
                roomDTO.removeGuest(userToLeaveUid);
                //transaction.set(roomRef, roomDTO.toMap()); todo why this is not working?
                transaction.update(roomRef, roomDTO.toPlain());
            }
        })

    }

    static async approvePendingGuestInRoom(guestToAccept: Player, roomId: string) {
        const roomRef = firebase.firestore().collection('rooms').doc(roomId);

        return firebase.firestore().runTransaction(async (transaction) => {
            const  roomSnapshot = await transaction.get(roomRef);
            if (roomSnapshot.exists) {
                let room = new RoomDTO(roomSnapshot.data());
                room.removePendingGuest(guestToAccept.uid);
                room.addGuest(guestToAccept)
                transaction.set(roomRef, room.toPlain(), {merge: true});
            }
        })


    }

    static async updateUserData(firebaseUser: firebase.User) {
        const userRef = firebase.firestore().collection('users').doc(firebaseUser.uid);

        return firebase.firestore().runTransaction(async (transaction) => {
            const userSnapshot = await transaction.get(userRef)
            let user:AppUser;
            if (userSnapshot.exists) {
                user = new AppUser(userSnapshot.data());
                console.log("user 1", user)
                user.merge(AppUser.fromCredential(firebaseUser))
                console.log("user 2", user)
            } else {
                user = AppUser.fromCredential(firebaseUser);
                user.roomId = null;
            }
            transaction.set(userRef, user.toPlain(), {merge: true});

        })
    }

    static goToMyRoom(user: AppUser) {
        const userRef = firebase.firestore().collection('users').doc(user.uid);
        const roomRef = firebase.firestore().collection('rooms').doc(user.uid);

        return firebase.firestore().runTransaction(async (transaction) => {
            const [userSnapshot, roomSnapshot] = await Promise.all([transaction.get(userRef), transaction.get(roomRef)]);
            if (userSnapshot.exists) {
                let user = new AppUser(userSnapshot.data());
                user.roomId = user.uid;
                transaction.set(userRef, user.toPlain());
            }
            if (!roomSnapshot.exists) {
               let room =  RoomDTO.createRoom(user);
                transaction.set(roomRef,room.toPlain(), {merge: true});
            }
        })
    }
}

class RoomService {

    static setOnRoomChange(roomId:string|null, callback:Function){ //todo better types and is this correct way?
        if(roomId==null){return}
        firebase.firestore().collection('rooms').doc(roomId).onSnapshot((doc)=>callback(doc));
    }

    static async findRoom(roomId: string): Promise<RoomDTO | null> {
        const roomDocument = await firebase.firestore().collection('rooms').doc(roomId).get();
        if(roomDocument.exists){
            return new RoomDTO(roomDocument.data());
        }
        return null;
    }


    static addPendingPlayerToRoom(userToJoin: AppUser, room: RoomDTO)  {


        const roomRef = firebase.firestore().collection('rooms').doc(room.id);
        const userRef = firebase.firestore().collection('users').doc(userToJoin.uid);

        return firebase.firestore().runTransaction(async (transaction) => {
            const [userSnapshot, roomSnapshot] = await Promise.all([transaction.get(userRef), transaction.get(roomRef)]);
            if (userSnapshot.exists) {
                let user = new AppUser(userSnapshot.data());
                user.roomId = room.id;
                transaction.set(userRef, user.toPlain());
            }
            if (roomSnapshot.exists) {
                let currentRoom = new RoomDTO(roomSnapshot.data());
                 currentRoom.addPendingGuest(Player.fromUser(userToJoin));
                transaction.set(roomRef, currentRoom.toPlain(), {merge: true});
            }
        })
    }
}


class GameService{

    static setOnGameChange(gameId:string, callback:Function){ //todo better types and is this correct way?
        firebase.firestore().collection('games').doc(gameId).onSnapshot((doc)=>callback(doc));
    }

    static async saveCardsOfUser(gameId:string, cards: string[], userId:string) {

        const gameRef = firebase.firestore().collection('games').doc(gameId);

        return firebase.firestore().runTransaction(async (transaction) => {
            const gameSnapshot = await transaction.get(gameRef);
            if (gameSnapshot.exists) {
                let game = new GameDTO(gameSnapshot.data());
                game.setCardsOfPlayer(cards, userId);
                transaction.set(gameRef, game.toPlain());
            }

        })
    }

    static async skipCard(game: GameDTO) {
        game.setRandomAvailableCelebrity();
        await firebase.firestore().collection('games').doc(game.id).set(game.toPlain(), {merge: true});
    }

    static async solved(game: GameDTO) {
        if (game.turnDeadline && game.turnDeadline < new Date()) {
            game.setRandomAvailableCelebrity();
            game.endCurrentTurn();
            await firebase.firestore().collection('games').doc(game.id).set(game.toPlain(), {merge: true});
        } else {
            const oldRound = game.round;
            game.addPointAndContinue();
            if (oldRound != game.round) {
                new Audio('http://hra.najvzdelavanie.sk/gong.mp3').play();
            }
            await firebase.firestore().collection('games').doc(game.id).set(game.toPlain(), {merge: true});
        }
    }

    static async endTurn(game: GameDTO) {
        game.setRandomAvailableCelebrity();
        game.endCurrentTurn();
        await firebase.firestore().collection('games').doc(game.id).set(game.toPlain(), {merge: true});
    }

    static async startTurn(game: GameDTO) {
        const minutes = 1;//todo put this to settings
        game.turnDeadline = new Date(new Date().getTime() + minutes * 60000)
        await firebase.firestore().collection('games').doc(game.id).set(game.toPlain(), {merge: true});
    }

    static startGameInRoom(gameId: string, room:RoomDTO) {
        const roomRef = firebase.firestore().collection('rooms').doc(room.id);
        const gameRef = firebase.firestore().collection('games').doc(gameId);

        return firebase.firestore().runTransaction(async (transaction) => {
            const [gameSnapshot, roomSnapshot] = await Promise.all([transaction.get(gameRef), transaction.get(roomRef)]);
            if (!gameSnapshot.exists) {
                transaction.set(gameRef, GameDTO.newGame(gameId, room).toPlain());
            }
            if (roomSnapshot.exists) {
                let currentRoom = new RoomDTO(roomSnapshot.data());
                currentRoom.gameId = gameId;
                //transaction.update(roomRef, map);
                transaction.set(roomRef, currentRoom.toPlain(), {merge: true});
            }
        })
    }

    static async saveGame(game: GameDTO) {
        console.log("saveGame game", game)
        await firebase.firestore().collection('games').doc(game.id).set(game.toPlain(), {merge: true});
    }

    static async startGamePlay(gameId:string) {
        const gameRef = firebase.firestore().collection('games').doc(gameId);

        return firebase.firestore().runTransaction(async (transaction) => {
            const gameSnapshot= await transaction.get(gameRef);
            if (gameSnapshot.exists) {
                let game = new GameDTO(gameSnapshot.data());
                game.prepareRound();
                game.celebritiesGamePhase="playing";
                game.round=1;
                game.setNextTeamAndPlayer();
                transaction.set(gameRef,game.toPlain());
            }
        })

    }

    static deleteGame(room: RoomDTO) {
        let gameRef:firebase.firestore.DocumentReference ;
        if(room.gameId) {
            gameRef = firebase.firestore().collection('games').doc(room.gameId);
        }
        const roomRef = firebase.firestore().collection('rooms').doc(room.id);

        return firebase.firestore().runTransaction(async (transaction) => {
            let gameSnapshot, roomSnapshot;
            if(gameRef) {
                [gameSnapshot, roomSnapshot] = await Promise.all([transaction.get(gameRef), transaction.get(roomRef)]);
                if (gameSnapshot.exists) {
                    transaction.delete(gameRef);
                }
            } else {
                roomSnapshot = await transaction.get(roomRef);
            }
            if (roomSnapshot.exists) {
                let room = new RoomDTO(roomSnapshot.data());
                room.gameId = null;
                transaction.set(roomRef, room.toPlain(), {merge: true});
            }
        })
    }
}


class Team{
    players:Player[] ;
    teamName:string ;
    currentPlayerIndex:number ;
    solved:number ;


    constructor(map:any) {
        this.players = map?.players?.map((rawPlayer:any)=>new Player(rawPlayer)) || [];
        this.teamName = map?.teamName ?? '';
        this.currentPlayerIndex = map?.currentPlayerIndex ?? null;
        this.solved = map?.solved ?? 0;
    }

    public containsPlayer(uid:string):boolean {
        return this.players.some((player) => player.uid==uid);
    }

    isPlaying(uid:string):boolean {
        return this.players[this.currentPlayerIndex].uid == uid;
    }

    addPoint() {
        if(this.solved==null){
            this.solved=0;
        }
        this.solved++;
    }
    setNextPlayer() {
        if(this.currentPlayerIndex == null){
            this.currentPlayerIndex = 0;
        } else {
            this.currentPlayerIndex = (this.currentPlayerIndex + 1) % this.players.length;
        }
    }
    getCurrentPlayer(){
        return this.players[this.currentPlayerIndex];
    }

    toPlain() {
        return {
            players:this.players.map(player=>player.toPlain()),
            teamName:this.teamName,
            currentPlayerIndex:this.currentPlayerIndex,
            solved:this.solved,
        };
    }
}

class RoomDTO{
    roomOwner:Player;
    pendingGuests:Player[];
    guests:Player[];
    gameId:string|null
    id:string;
    constructor(map:any) {
        console.log("RoomDTO constructor map:", map)
        this.roomOwner = new Player(map.roomOwner);
        this.id =map.roomOwner.uid;
        this.pendingGuests=map.pendingGuests?.map((rawPlayer:any)=>new Player(rawPlayer));
        this.guests=map.guests?.map((rawPlayer:any)=>new Player(rawPlayer));
        this.gameId=map.gameId;
    }

    merge(map:any){
        if(map.roomOwner!==undefined) {
            this.roomOwner = new Player(map.roomOwner);
        }
        if(map.id!==undefined) {
            this.id =map.roomOwner.uid;
        }
        if(map.pendingGuests!==undefined) {
            this.pendingGuests=map.pendingGuests?.map((rawPlayer:any)=>new Player(rawPlayer));
        }
        if(map.guests!==undefined) {
            this.guests=map.guests?.map((rawPlayer:any)=>new Player(rawPlayer));
        }
        if(map.gameId!==undefined) {
            this.gameId=map.gameId;
        }
    }

    toPlain() {
        return {
            roomOwner:this.roomOwner.toPlain(),
            pendingGuests:this.pendingGuests?.map((player)=>player.toPlain()),
            guests:this.guests?.map((player)=>player.toPlain()),
            gameId:this.gameId,
            id:this.id,
        };
    }

    removePendingGuest(uid: string) {
        this.pendingGuests = this.pendingGuests.filter((player)=>player.uid!=uid);
    }

    addGuest(guest:Player){
        if(this.guests == null){
            this.guests = [];
        }
        if(!this.guests.some(player=>player.uid === guest.uid)) {
            this.guests.push(guest);
        }
    }

    removeGuest(uid: string) {
        this.guests = this.guests.filter((player)=>player.uid!=uid);
        this.pendingGuests = this.pendingGuests.filter((player)=>player.uid!=uid);
    }

    isApproved(user:AppUser) {
        return this.guests.some((player)=>player.uid==user.uid);
    }

    static createRoom(user: AppUser) {
        return new RoomDTO( {
            roomOwner : Player.fromUser(user),
            id :user.uid,
            pendingGuests:[],
            guests:[],
            gameId:null
        })
    }

    addPendingGuest(playerToAdd: Player) {
        if(this.pendingGuests == null){
            this.pendingGuests = [];
        }
        if(!this.pendingGuests.some(player=>player.uid === playerToAdd.uid)) {
            this.pendingGuests.push(playerToAdd);
        }
    }

    getAllPlayers(){
        let allPlayers = [...this.guests];
        allPlayers.push(this.roomOwner);
        return allPlayers;
    }

}

class Player {
    uid:string;
    photoUrl:string;
    displayName:string;
    mail:string;
    constructor(map:any) {
        console.log("player constructor map", map)
        this.uid = map.uid;
        this.photoUrl = map.photoUrl;
        this.displayName = map.displayName;
        this.mail = map.mail;
    }

    static fromUser(user: AppUser) {
        console.log("fromUser user", user);
        return new Player({
            uid:user.uid,
            photoUrl: user.photoUrl?? "",
            displayName:user.displayName,
            mail:user.email,
        })
    }

    toPlain() {
        return {
            uid:this.uid,
            photoUrl:this.photoUrl,
            displayName:this.displayName,
            mail:this.mail,
        };
    }
}

function getRandomInt(max:number) {
    return Math.floor(Math.random() * Math.floor(max));
}

class GameDTO{
    id:string;
    roomId:string;
    ownerId:string;
    gameType:string;
    celebritiesPerUserId:any//todo Map<string, Array<string>>;
    celebritiesUsedInRound:string[];
    celebritiesAvailableInRound:string[];
    currentCelebrity:string;
    currentTeamIndex:number;
    teams:Team[];
    round:number;
    celebritiesGamePhase:string;
    turnDeadline:Date|null;
    countPerMinute:boolean;


    constructor(map:any) {
        this.id = map.id;
        this.roomId = map.roomId;
        this.ownerId = map.ownerId;
        this.gameType = map.gameType;
        this.celebritiesPerUserId = map.celebritiesPerUserId;
        this.celebritiesUsedInRound = map.celebritiesUsedInRound;
        this.celebritiesAvailableInRound = map.celebritiesAvailableInRound;
        this.currentCelebrity = map.currentCelebrity;
        this.currentTeamIndex = map.currentTeamIndex;
        this.teams = map.teams.map((team:any)=>new Team(team));
        this.round = map.round;
        this.celebritiesGamePhase = map.celebritiesGamePhase;
        this.turnDeadline = !!map.turnDeadline ? new Date(map.turnDeadline.seconds*1000) : null;
        this.countPerMinute = map.countPerMinute;
    }

    merge(map:any){
        if(map.id!==undefined){
            this.id = map.id;
        }
        if(map.roomId!==undefined){
            this.roomId = map.roomId;
        }
        if(map.ownerId!==undefined){
            this.ownerId = map.ownerId;
        }
        if(map.gameType!==undefined){
            this.gameType = map.gameType;
        }
        if(map.celebritiesPerUserId!==undefined){
            this.celebritiesPerUserId = map.celebritiesPerUserId;
        }
        if(map.celebritiesUsedInRound!==undefined){
            this.celebritiesUsedInRound = map.celebritiesUsedInRound;
        }
        if(map.celebritiesAvailableInRound!==undefined){
            this.celebritiesAvailableInRound = map.celebritiesAvailableInRound;
        }
        if(map.currentCelebrity!==undefined){
            this.currentCelebrity = map.currentCelebrity;
        }
        if(map.currentTeamIndex!==undefined){
            this.currentTeamIndex = map.currentTeamIndex;
        }
        if(map.teams!==undefined){
            this.teams = map.teams.map((team:any)=>new Team(team));
        }
        if(map.round!==undefined){
            this.round = map.round;
        }
        if(map.celebritiesGamePhase!==undefined){
            this.celebritiesGamePhase = map.celebritiesGamePhase;
        }
        if(map.turnDeadline!==undefined){
            this.turnDeadline = !!map.turnDeadline ? new Date(map.turnDeadline.seconds*1000) : null;
        }
        if(map.countPerMinute!==undefined){
            this.countPerMinute = map.countPerMinute;
        }
    }

    static newGame(id:string, room:RoomDTO){
        return new GameDTO({
            id : id,
            roomId: room.id,
            ownerId: room.roomOwner.uid,
            gameType:"celebrities",
            celebritiesPerUserId:{},
            celebritiesUsedInRound:[],
            celebritiesAvailableInRound:[],
            currentCelebrity:null,
            currentTeamIndex:null,
            teams:[],
            round:1,
            celebritiesGamePhase:"settings",
            turnDeadline:null,
            countPerMinute:true
        });
    }

    isPlayingTeamOfPlayer(uid:string):boolean {
        console.log("isPlayingTeamOfPlayer uid", uid)
        console.log("isPlayingTeamOfPlayer this.teams[this.currentTeamIndex]", this.teams[this.currentTeamIndex])
        console.log("isPlayingTeamOfPlayer  this.currentTeamIndex",this.currentTeamIndex)
        console.log("isPlayingTeamOfPlayer  this.teams",this.teams)
        const currentTeam =  this.teams[this.currentTeamIndex]
        console.log("isPlayingTeamOfPlayer  currentTeam",currentTeam)

        return currentTeam.containsPlayer(uid);
    }

    setRandomAvailableCelebrity() {
        const randomIndex:number = getRandomInt(this.celebritiesAvailableInRound.length);
        this.currentCelebrity = this.celebritiesAvailableInRound[randomIndex];
    }

    setNextTeamAndPlayer() {
        if(this.currentTeamIndex == null){
            this.currentTeamIndex = 0;
        } else {
            this.currentTeamIndex = (this.currentTeamIndex + 1) % this.teams.length;
        }
        this.teams[this.currentTeamIndex].setNextPlayer();
    }

    setNextPlayerWithinTeam() {
        if(this.currentTeamIndex == null){
            this.currentTeamIndex = 0;
        }
        this.teams[this.currentTeamIndex].setNextPlayer();
    }

    //todo
    //setTeams(Map<String, List<PlayerDTO>> playersPerName){
    //_teams = List();
    //playersPerName.forEach((key, value) {
    //_teams.add(
    //    TeamDTO.emptyFromPlayersAndName(players: value, teamName: key)
    //);
    //});


    getCurrentTeam():Team {
        return this.teams[this.currentTeamIndex];
    }

    isPlayingPlayer(uid:string):boolean {
        return this.teams[this.currentTeamIndex].isPlaying(uid);
    }


    addPointAndContinue() {
        this.teams[this.currentTeamIndex].addPoint();
        this.celebritiesUsedInRound.push(this.currentCelebrity);
        this.celebritiesAvailableInRound = this.celebritiesAvailableInRound.filter((celebrity)=>celebrity!=this.currentCelebrity);
        if(this.celebritiesAvailableInRound.length == 0){
            if(this.round < 3){ //todo solve nie kreslenie
                this.round++;
                this.prepareRound();
            } else {
                this.celebritiesGamePhase = "ended";
            }
        } else {
            this.setRandomAvailableCelebrity();
        }


    }

    prepareRound() {
        this.celebritiesUsedInRound = [];
        this.celebritiesAvailableInRound = this.getAllCelebrities();
        this.setRandomAvailableCelebrity();
    }

    getAllCelebrities():Array<string> {
        let allCelebrities:Array<string> = []
        for(let uid in this.celebritiesPerUserId ){
            allCelebrities = allCelebrities.concat(this.celebritiesPerUserId[uid])
        }

        return allCelebrities;
    }


    endCurrentTurn() {
        this.setNextTeamAndPlayer();
        this.turnDeadline=null;
    }

    getWinningTeams():Array<Team> {
        let maxSolved: number = 0;
        let winners: Array<Team> =[];
        this.teams.forEach((element) => {
            if(element.solved > maxSolved){
                maxSolved = element.solved;
                winners=[];
            }
            if(element.solved == maxSolved){
                winners.push(element);
            }
        });
        return winners;

    }


    toPlain() {
        return {
            id:this.id,
            roomId:this.roomId,
            ownerId:this.ownerId,
            gameType:this.gameType,
            celebritiesPerUserId:this.celebritiesPerUserId,
            celebritiesUsedInRound:this.celebritiesUsedInRound,
            celebritiesAvailableInRound:this.celebritiesAvailableInRound,
            currentCelebrity:this.currentCelebrity,
            currentTeamIndex:this.currentTeamIndex,
            teams:this.teams.map((team)=>team.toPlain()),
            round:this.round,
            celebritiesGamePhase:this.celebritiesGamePhase,
            turnDeadline:this.turnDeadline,
            countPerMinute:this.countPerMinute,
        } ;
    }

    goToPreparingPhase() {
        this.celebritiesGamePhase="preparing";
        this.countPerMinute=true;
    }

    setCardsOfPlayer(cards: string[], userId: string) {
       if(this.celebritiesPerUserId==null){
           this.celebritiesPerUserId = {};
       }
        this.celebritiesPerUserId[userId] = cards;
    }
}

class AppUser{

    roomId:string|null;
    uid:string;
    email:string;
    photoUrl:string;
    displayName:string;

    constructor(map:any) {
        console.log("AppUser Map", typeof map, map)
        this.roomId = map.roomId;
        this.uid = map.uid;
        this.email = map.email;
        this.photoUrl = map.photoURL ?? "";
        this.displayName = map.displayName;
    }

    merge(map:any){
        console.log("merging", this, map);
        if(map.roomId!==undefined){
            this.roomId = map.roomId ?? null;
        }
        if(map.uid!==undefined){
            this.uid = map.uid;
        }
        if(map.email!==undefined){
            this.email = map.email;
        }
        if(map.photoURL!==undefined){
            this.photoUrl = map.photoURL ?? "";
        }
        if(map.displayName!==undefined){
            this.displayName = map.displayName;
        }
    }

    toPlain() {
        return {
            roomId:this.roomId,
            uid:this.uid,
            email:this.email,
            photoUrl:this.photoUrl,
            displayName:this.displayName,
        };
    }

    static fromCredential(user: firebase.User) {
        return new AppUser({
            roomId:undefined,
            uid:user.uid,
            email:user.email,
            photoURL:user.photoURL,
            displayName:user.displayName,
        });
    }
}

class RoomNotFound extends React.Component<{ searchTerm: string }> {
    render() {
        return <div>Izba {this.props.searchTerm} sa nenašla... Možno majiteľ izby ešte nikdy nevstúpil do svojej izby a preto neexistuje, keď do nej vstúpi, skús to ešte raz aj ty :)</div>;
    }
}

class FoundRoom extends React.Component<{ room: RoomDTO}> {
    constructor(props:{ room: RoomDTO}) {
        super(props);
        this.joinRoom = this.joinRoom.bind(this);

    }

    async joinRoom() {
        const user = this.context;
        console.log(user);
        await RoomService.addPendingPlayerToRoom(user, this.props.room);

    }

    render() {

        return <div>Našla sa izba používateľa {this.props.room.roomOwner.displayName} ({this.props.room.roomOwner.mail})
            <Button onClick={this.joinRoom}>Pripojiť sa</Button>
        </div>;
    }
}
FoundRoom.contextType = AppUserContext;


class HomeLoggedIn extends React.Component<any, {foundRoom:RoomDTO|null, searchTerm:string, searching:boolean, changeAfterSubmti:boolean} >{

    constructor(props:any) {
        super(props);
        this.state = {
            foundRoom: null,
            searchTerm:"",
            changeAfterSubmti:false,
            searching:false,
        }
        this.findRoomByUserEmailOrRoomId = this.findRoomByUserEmailOrRoomId.bind(this);
    }

    async findRoomByUserEmailOrRoomId(event: any) { //todo type...
        event.preventDefault();
        console.log("searchTerm", this.state.searchTerm);
        if(this.state.searchTerm == ""){
            return;
        }
        this.setState({searching:true, changeAfterSubmti:false});
        const user = await UserService.findUserByEmail(this.state.searchTerm);
        console.log("user", user);
        let roomIdToSearch;
        if(user){
            roomIdToSearch = user.uid;
        } else {
            roomIdToSearch = this.state.searchTerm;
        }
        console.log(roomIdToSearch);
        const room = await RoomService.findRoom(roomIdToSearch);
        this.setState({
            searching:false,
            foundRoom:room
        })
    }


    getSearchForm(){
        return <>
            <div className="margin1em">alebo...</div>
        <form onSubmit={this.findRoomByUserEmailOrRoomId}>
            <TextField
                onChange={(e)=>this.setState({searchTerm:e.target.value, foundRoom:null, changeAfterSubmti:true})}
                id="findRoom"
                label="Vyhľadať izbu podľa emailu alebo id izby"
                name={"searchTerm"}
            /> {/*todo better*/}
            <IconButton type={"submit"} >Hľadať</IconButton>
        </form>
            </>
    }

    render() {
        return (
            <div>
                <div id="goToMyRoom"><Button style={{...styles.button}}  onClick={()=>UserService.goToMyRoom(this.context)} >Prejdi do svojej izby</Button></div>
                {this.state.searching ? "Hľadá sa izba..." : this.getSearchForm()}
                {!this.state.searching && !this.state.changeAfterSubmti && this.state.foundRoom && <FoundRoom room={this.state.foundRoom} /> }
                {!this.state.searching && !this.state.changeAfterSubmti && !this.state.foundRoom && this.state.searchTerm != "" && <RoomNotFound searchTerm={this.state.searchTerm}/>}
            </div>
        );
    }
}
HomeLoggedIn.contextType = AppUserContext;


class Info extends React.Component {
    render() {
        return <>
            <p>
                Keďže aktuálne máme dosť obmedzené stretávať sa a hrať sa naživo vytvoril som túto stránku. Vďaka nej
                je hranie sa online o čosi jednoduchšie.
            </p>
            <p>
                Každý prihlásený užívateľ môže vo "svojej izbe" hostiť hru.  Po prihlásení môžeš prejsť do svojej izby
                alebo vyhľadať podla emailu izbu svojho kamaráta, v ktorej sa bude hrať hra.
            </p>
            <p>
                Ak má niekto záujem vojsť do tvojej izby, zobrazí sa ti to a ty máš možnosť sa rozhodnúť či ho do izby pustíš alebo nie...
            </p>
            <p>
                <strong> Táto stránka slúži ako "spoločný stôl na ktorom je spoločná kôpka kariet.". Na to aby ste sa videli a počuli, využite váš oblúbený nástroj na videohovor. </strong>
            </p>
            <p>
                Stránka zatial obsahuje jedinú hru - osobnosti. Je to veľmi jedoduchá hra, ktorá sa podobá na známu hru Activity.
                <ul>
                    <li>
                        Príprava na hru:
                        <ul>
                            <li>na začiatku sa hráči rozdelia do týmov. Ideálne po dvoch ale môže ich byť v týme kludne aj viac</li>
                            <li>následne každý vymyslí a zapíše niekoľko osobností</li>
                            <li>nakoniec sa osobnosti od všetkých hráčov "hodia do spoločnej kôpky"</li>
                        </ul>
                    </li>
                    <li>
                        Priebeh hry:
                        <ul>
                            <li>Jeden z hráčov tímu, ktorý je na rade postupne "ťahá z kôpky" osobnosti a snaží sa ich popísať svojim spoluhráčom.
                                Kolko osobností sa im v priebehu minúty podarí uhádnuť, toľko bodov ako tím získavajú </li>
                            <li> Hra ma 3 kolá: v prvom kole sa osobnosti opisujú. Keď sa minú všetky osobnosti. Nasleduje druhé kolo. Teraz už hráči môžu
                                používať len 3 slová. Avšak už viete, aké osobnosti sa v kôpke nachádzajú. Keď sa minú větky kartičky nasleduje posledné kolo, kedy hráči môžu už iba ukazovať osobnosť bez slov.</li>
                        </ul>
                    </li>
                </ul>
            </p>
            <p>
                Disclaimer: Túto stránku som vytvoril narýchlo aby som sa čím skôr mohol začať s kamarátmi hrať. Je preto dosť možné že sa nájdu chyby a niečo nebude fungovať. V takom prípade skús obnoviť stránku. A ak mi napíšěs mail, tak sa to pokúsim opraviť :)
            </p>
            <p>
                A to je zatiaľ všetko. Ak sa vám stránka páči a chceli by ste aby som ju rozširoval alebo ak niečo nefunguje napíšte mi mail na siller.jakub@gmail.com. Ak si developer a chceš sa pridať k vývoju (Typescript + React + Firebase), ozvi sa :)
            </p>
            <p>
                <strong>Good luck! Have Fun :)</strong>
            </p>
        </>;
    }
}

class LoginScreen extends React.Component<{onLogin:Function, onStartLoggingIn:Function, onLoginFailed:Function},any>{
    constructor(props:any) {
        super(props);
        this.handleClick = this.handleClick.bind(this)
    }
    async handleClick() {
        this.props.onStartLoggingIn()
        const provider = new firebase.auth.GoogleAuthProvider();
        await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        firebase.auth().signInWithPopup(provider)
            .then((async userCredential => {
                if (userCredential.user != null) {
                    await UserService.updateUserData(userCredential.user)
                }
                firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
                this.props.onLogin(userCredential)
            }))
            .catch(e => { console.error(e); this.props.onLoginFailed(e);});
    }

    render() {
        return (<div>
                <header className="App-header"> {/*todo toto je copy paste ... resolve..*/}
                    <span className="title">Zahrajme sa v0.2.0</span>

                    <div className="actions"><Button style={{...styles.button}}  onClick={this.handleClick} >Prihlásiť sa Google účtom</Button></div>
                </header>
               <div id="LoginScreenContent">
                <div>
                    <h1>Vitaj!</h1>
                    <Info />
                </div>
            </div>
            </div>
        );
    }
}


function PlayerInTeam(props: { index:any, player:Player }) {
    const { index, player } = props;
    let style = {
        backgroundColor: "red"
    };

    return (
        <Draggable draggableId={player.uid} index={index}>
            {provided => (
                <span
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <Chip avatar={<Avatar src={player.photoUrl} />}  label={player.displayName}/>
                    {/*<img  className={"avatar"} src={player.photoUrl}/>{player.displayName}*/}
                </span>
            )}
        </Draggable>
    );
}
function TeamMembers(props:{droppableId:any, players:Player[]}) {
    const { droppableId, players } = props;


    return (
        <Droppable droppableId={droppableId} >
            {provided => (
                <div {...provided.droppableProps} ref={provided.innerRef} className="TeamMembers">

                    {players.map((player, index:any) => {
                        return (
                           <PlayerInTeam key={player.uid}  index={index} player={player} />
                        );
                    })}
                    {provided.placeholder}
                    {players.length == 0 && droppableId!=  "playersWithoutTeam" && <div>Presuň hráčov do týmu sem</div>}

                </div>
            )}
        </Droppable>
    );
}

class SettingCelebritiesGame extends React.Component<{room:RoomDTO, game:GameDTO}, {  teams:Team[], confirmationOpen:boolean, open:boolean, errorMessage:string|null }> {


    constructor(props:any) {
        super(props);
        this.state={
            teams:[],
            open:false,
            confirmationOpen:false,
            errorMessage:null
        }
        this.handleOnDragEnd= this.handleOnDragEnd.bind(this)
        this.addTeam= this.addTeam.bind(this)
        this.getAllPlayersWithoutTeam= this.getAllPlayersWithoutTeam.bind(this)
        this.handleTeamNameChange= this.handleTeamNameChange.bind(this)
        this.saveTeams = this.saveTeams.bind(this)
        this.startAGame = this.startAGame.bind(this)
        this.teamsAreValid = this.teamsAreValid.bind(this)
        this.getNotEmptyTeams = this.getNotEmptyTeams.bind(this)

    }

    getAllPlayersWithoutTeam(){
        const playersWithteam = this.state.teams.flatMap((team)=>team.players);
        return this.props.room.getAllPlayers().filter((guest)=> playersWithteam.find((player)=>player.uid === guest.uid) == null )
    }

    addTeam(){
        const newTeams = this.state.teams.concat(new Team({}));
        this.setState({teams:newTeams})
    }

    parseTeamIndex(val:string):number|null{
        const myRegexp = /^team(\d)$/g;
        const res =  myRegexp.exec(val);
        console.log("res",res)
        return res == null || res.length == 0 ? null : Number(res[1]);
    }

    handleOnDragEnd(val:any){
        console.log("handleOnDragEnd val",val)
        /// A different way!
        const { draggableId, source, destination } = val;
        console.log("source.droppableId , destination.droppableId",source?.droppableId , destination?.droppableId)
        if( source?.droppableId == destination?.droppableId){
            console.log("source.droppableId == destination.droppableId")
            return;
        }
        console.log("source.droppableId != destination.droppableId")

        const sourceTeam = this.parseTeamIndex(source.droppableId);
        console.log("sourceTeam",sourceTeam);

        const destinationTeam = this.parseTeamIndex(destination.droppableId);
        console.log("destinationTeam",destinationTeam);

        let movingPlayer = this.props.room.guests.find((player)=> player.uid===draggableId);
        if(movingPlayer == null && draggableId == this.props.room.roomOwner.uid){
            movingPlayer = this.props.room.roomOwner;
        }
        console.log("movingPlayer",movingPlayer);
        if(movingPlayer != null) {
            let newTeams = this.state.teams;
            if (sourceTeam != null) {
                newTeams[sourceTeam].players = newTeams[sourceTeam].players.filter((player) => player.uid != draggableId);
            }
            if (destinationTeam != null) {
                console.log("destination.index",destination.index)
                console.log("newTeams[destinationTeam].players",newTeams[destinationTeam].players)
                newTeams[destinationTeam].players.splice(
                    destination.index,
                    0,
                    movingPlayer
                );
                console.log("newTeams[destinationTeam].players",newTeams[destinationTeam].players)
            }
            this.setState({teams:newTeams});
            console.log("new state set", newTeams)
        }else {
            console.log("something weird happened");
        }

    }


    handleTeamNameChange(index:number){
        let fn =  (event:any)=>{ //todo type
            //console.log("event", event, index);
            const newTeamName = event.target.value;
            let newTeams = this.state.teams;
            newTeams[index].teamName = newTeamName;
            this.setState({teams:newTeams})
        }
        return fn.bind(this)
    }



    async saveTeams() {
        const teams = this.getNotEmptyTeams(this.state.teams);
        if(!this.teamsAreValid(teams)){
            this.setState({open:true})
            return;
        }
        const gameToSave = this.props.game;
        gameToSave.teams = teams;
        await GameService.saveGame(gameToSave);
    }

    getNotEmptyTeams(teams:Team[]){
        return teams.filter((team)=> ( team.teamName!="" && team.teamName!=null) || team.players.length > 0)
    }

    async startAGame() {
        const teams = this.getNotEmptyTeams(this.state.teams);
        if(!this.teamsAreValid(teams)){
            this.setState({open:true})
            return;
        }
        const gameToSave = this.props.game;
        gameToSave.teams = teams;
        gameToSave.goToPreparingPhase();
        await GameService.saveGame(gameToSave);
    }

    handleClose = () => {
        this.setState({open:false})
    };
    handleCloseConfirmation = () => {
        this.setState({confirmationOpen:false})
    };
    render() {
        return <div id="SettingCelebritiesGame">
            <h1 className="phaseTitle">Vytvor  tími</h1>
            <p>
                Tími sa ostatným zobrazia po tom čo ich uložíš...
            </p>
            <DragDropContext onDragEnd={this.handleOnDragEnd}>

                <div className="wrapper">
                    <div>Hráči bez tímu:</div>
                    <TeamMembers
                        droppableId={"playersWithoutTeam"}
                        players={this.getAllPlayersWithoutTeam()}
                    />
                    {this.state.teams.map((team:any,index:any)=>
                     <div className="teamInSettings">   <TextField  label="Názov tímu" value={team.teamName} onChange={this.handleTeamNameChange(index)}/>
                         <div className="membersTitle">Hráči v tíme:</div>
                         <TeamMembers
                        droppableId={"team"+index}
                        players={this.state.teams[index].players}
                    /></div>
                        ) }
                </div>
            </DragDropContext>

            <div id="SettingControls">
            <Button id="addTeam" style={{...styles.button, ...styles.buttonBlue}} onClick={this.addTeam}>Ďalší tím</Button>
            <Button id="saveTeams" style={{...styles.button, ...styles.buttonGreen}} onClick={this.saveTeams}>Uložiť tími</Button>
            <Button id="startGame" style={{...styles.button, ...styles.buttonGreen}} onClick={
                ()=>{
                    const teams = this.getNotEmptyTeams(this.state.teams);
                    if(!this.teamsAreValid(teams)){
                        this.setState({open:true})
                        return;
                    }
                    this.setState({confirmationOpen:true})
                }
                }>Uložiť tími a spustiť hru</Button>
            </div>
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Problém s tímami"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Nastavenie tímov nie je v poriadku...
                        <div>{this.state.errorMessage}</div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={this.state.confirmationOpen}
                onClose={this.handleCloseConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Chystáš sa spustiť hru..."}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Po spustení hry už nebude možné upravovať tími...
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseConfirmation} color="primary" autoFocus>
                        Ešte upraviť tími
                    </Button>
                    <Button onClick={this.startAGame} color="primary" autoFocus>
                        Spustiť hru
                    </Button>
                </DialogActions>
            </Dialog>
        </div>;
    }

    private teamsAreValid(teams: Team[]) {
        if(teams?.length == 0){
            this.setState({errorMessage:"Nie sú vytvorené žiadne tími..."})
            return false;
        }
        if(teams.find((team)=> team.teamName =="") != null){
            this.setState({errorMessage:"Exituje tím, v ktorom sú hráči a nemá meno"})
            return false;
        }
        const teamWithoutPlayers = teams.find((team)=> team.players.length == 0);
        if( teamWithoutPlayers != null){
            this.setState({errorMessage:"tím "+teamWithoutPlayers.teamName+" nemá hráčov"})
            return false;
        }

        const duplicate = teams.find((item, index) => teams.indexOf(item) != index);
        if( duplicate != null ){
           this.setState({errorMessage:"Viac ako jeden tím sa vola '"+duplicate.teamName+"'"})
           return false;
       }
       return true
    }
}

class ViewSettingCelebritiesGame extends React.Component<{game:GameDTO}> {
    render() {
        return (<div id="ViewSettingCelebritiesGame">
            <h1 className="phaseTitle">Vytváranie tímov</h1>
            <div className="phaseTitle">tímy vytvára hostiteľ, keď ich uloží zobrazia sa tu</div>
            <ul>{this.props.game.teams.map((team)=><div><div>tím: {team.teamName}</div><ul>{team.players.map((player)=><li>{player.displayName} </li>)}</ul></div>)}</ul>
        </div>);
    }
}

class PreparingCelebritiesGame extends React.Component<{game:GameDTO, appUser:AppUser, isOwner:boolean}, {cards:Array<string>, confirmationOpen:boolean, open:boolean}> {
    constructor(props:any) {
        super(props);
        this.state=  {
            cards: props.game.celebritiesPerUserId[props.appUser.uid] ? props.game.celebritiesPerUserId[props.appUser.uid] : [""],
            open:false,
            confirmationOpen:false
        };
        this.onTextChange = this.onTextChange.bind(this)
        this.addCardRow = this.addCardRow.bind(this)
        this.saveCards = this.saveCards.bind(this)
        this.startGame = this.startGame.bind(this)
        this.handleClose = this.handleClose.bind(this)
    }
    //todo upddate state when new props....

    addCardRow(){
        const newCards = this.state.cards.concat('');
        this.setState(
            {
                cards:newCards
            }
        )
    }

    onTextChange(index:number){
        let fn =  (event:any)=>{ //todo type
            //console.log("event", event, index);V
            const newCard = event.target.value;
            let newCards = this.state.cards;
            newCards[index] = newCard;
            this.setState({cards:newCards})
        }
        return fn.bind(this)
    }

    async startGame(){
        await this.saveCards()
        if(this.props.game.getAllCelebrities().length == 0 && this.state.cards.length == 0){
            this.setState({open:true})
            return;
        }
        await GameService.startGamePlay(this.props.game.id)
    }

    async saveCards() { //todo loading...
        const filtered = this.state.cards.filter((card) => card != "");
        //@ts-ignore
        const uniq: string[] = [...new Set(filtered)];
        await GameService.saveCardsOfUser(this.props.game.id, uniq, this.props.appUser.uid);
    }

    handleClose = () => {
        this.setState({open:false})
    };
    handleCloseConfirmation= () => {
        this.setState({confirmationOpen:false})
    };
    render() {
        console.log("this.props.game.celebritiesPerUserId", typeof this.props.game.celebritiesPerUserId, this.props.game.celebritiesPerUserId)
        return (<div id="PreparingCelebritiesGame">
            <h1 className="phaseTitle">
                Pridaj osobnosti
            </h1>
            <div className="content">{/*todo redo*/}
                {this.state.cards.map((card,index)=><div><TextField label="napíš meno osobnosti" value={card} onChange={this.onTextChange(index)}/></div>)}
                <Button style={{...styles.button, ...styles.buttonBlue}} onClick={this.addCardRow}>Ďalšia osobnosť</Button>
                <Button style={{...styles.button, ...styles.buttonGreen}} onClick={this.saveCards}>Uložiť osobnosti</Button>
                {this.props.game.teams.map((team)=><div>
                    <div>tím: {team.teamName}</div>
                    <ul>{
                        team.players.map((player)=>
                            <li>{player.displayName}  má  {this.getCelebritiesCountSentence(player.uid)}</li>
                        )}
                    </ul>
                </div>)}
            </div>
            { this.props.isOwner && <Button  style={{...styles.button, ...styles.buttonOrange}} onClick={()=>this.setState({confirmationOpen:true})} >Uložiť a spustiť hru</Button>}
            <Dialog
                open={this.state.open}
                onClose={this.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Nikto neuložil žiadnu osobnosť..."}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Nikto neuložil žiadnu osobnosť, na to aby ste mohli začať hra potrebujete nejaké osobnosti uložiť...
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleClose} color="primary" autoFocus>
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={this.state.confirmationOpen}
                onClose={this.handleCloseConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Chystáš sa spustiť hru..."}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Po spustení hry už nebude možné upravovať osobnosti...
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleCloseConfirmation} color="primary" autoFocus>
                        Ešte upraviť osobnosti...
                    </Button>
                    <Button onClick={this.startGame} color="primary" autoFocus>
                        Spustiť hru
                    </Button>
                </DialogActions>
            </Dialog>
        </div>);
    }

    private getCelebritiesCountSentence(uid: string) {
        const count = this.props.game.celebritiesPerUserId[uid]? this.props.game.celebritiesPerUserId[uid].length : 0;
        if(count == 0){
            return "0 celebrít"
        } else if (count == 1){
            return "1 celebrítu"
        } else if( count < 5){
            return count + " celebrity"
        } else {
            return count + " celebrít"
        }
    }
}
PreparingCelebritiesGame.contextType = AppUserContext;


class Timer extends React.Component<{ game: GameDTO , onComplete:(...args: any[]) => any},any> {
    constructor(props:any) {
        super(props);
        this.renderer = this.renderer.bind(this)

    }

    renderer({minutes, seconds, completed }:any)  {
        if (completed) {
            // Render a completed state
            return <div id="timer" className="timeout">Čas vypršal!</div>;
        } else {
            // Render a countdown
            return <div id="timer" className="countDown">{("0" + minutes).slice(-2)}:{("0" + seconds).slice(-2)}</div>;
        }
    }


    render() {
        const ren = this.renderer;
        const deadline = this.props.game.turnDeadline ?? undefined
        const completeFn = this.props.onComplete;
        console.log("deadline",deadline, "now", new Date())
        return !!deadline ? <Countdown date={deadline} renderer={ren} onComplete={completeFn}/> : <div>Ešte sa nespustila časomiera...</div>
    }
}

class Presenting extends React.Component<{ game: GameDTO },{timeout:boolean}> {
    constructor(props:any) {
        super(props);
        this.state={
            timeout:!!props.game.turnDeadline && props.game.turnDeadline < new Date()
        }
    }
    render() {
        return <div>
            <div>  Si na rade ! </div>
            <WhatAmIDoing game={this.props.game} />
            {!this.state.timeout && !!this.props.game.turnDeadline && <Button style={{...styles.button, ...styles.buttonBlue}} onClick={()=>GameService.skipCard(this.props.game)} >Preskočiť</Button>}
            <div><GameCard game={this.props.game}/></div>
            {!!this.props.game.turnDeadline ? <Timer game={this.props.game} onComplete={()=>{
                    this.setState({timeout:true})
                    new Audio('http://hra.najvzdelavanie.sk/alarm.mp3').play();
                }}/> :
                <Button style={{...styles.button, ...styles.buttonGreen}}  onClick={()=>GameService.startTurn(this.props.game)}>Začať</Button>}
            {!this.state.timeout && !!this.props.game.turnDeadline
            && <Button style={{...styles.button, ...styles.buttonGreen}} onClick={()=>GameService.solved(this.props.game)}>Uhádnuté</Button> }
            {this.state.timeout && !!this.props.game.turnDeadline
            &&  <Button style={{...styles.button, ...styles.buttonOrange}} onClick={()=>GameService.endTurn(this.props.game)}>Ukončiť kolo</Button>
            }
        </div>
    }
}

class Guessing extends React.Component<{ game: GameDTO }> {
    render() {
        return <div id="guessing">
            <div className="yourAction"> Hádaš </div>
            <WhoAndWhatIsDoing game={this.props.game} />
            <Timer game={this.props.game} onComplete={()=>{}}/>
        </div>
    }
}

class GameCard extends React.Component<{ game: GameDTO }> {
    constructor(props:any) {
        super(props);
        this.getColor=this.getColor.bind(this);
    }
    getColor(){
        switch (this.props.game.round){
            case 1: return "#4CAF50"
            case 2: return "#40C4FF"
            case 3: return "#E040FB"
            default: return "black"
        }
    }
    render() {
        return <Card id="card" style={{color:this.getColor()}}>{this.props.game.currentCelebrity}</Card>;
    }
}

class WhoAndWhatIsDoing extends React.Component<{ game: GameDTO }> {
    constructor(props:any) {
        super(props);
        this.getActivity = this.getActivity.bind(this)
        this.getColor = this.getColor.bind(this)
    }

    getColor(){
        switch (this.props.game.round){
            case 1: return "#4CAF50"
            case 2: return "#40C4FF"
            case 3: return "#E040FB"
            default: return "black"
        }
    }

    getActivity(){
        switch (this.props.game.round){
            case 1: return "opisuje"
            case 2: return "3 slová"
            case 3: return "ukazuje"
            default: return "je na rade"
        }
    }
    render() {
        return <div id="whoAndWhatIsDoing" style={{color:this.getColor()}}>{this.props.game.getCurrentTeam().getCurrentPlayer().displayName} {this.getActivity()}</div>;
    }
}

class WhatAmIDoing extends React.Component<{ game: GameDTO }> {
    constructor(props:any) {
        super(props);
        this.getActivity = this.getActivity.bind(this)
        this.getColor = this.getColor.bind(this)
    }
    getActivity(){
        switch (this.props.game.round){
            case 1: return "opisuješ"
            case 2: return "3 slová"
            case 3: return "ukazuješ"
            default: return ""
        }
    }
    getColor(){
        switch (this.props.game.round){
            case 1: return "#4CAF50"
            case 2: return "#40C4FF"
            case 3: return "#E040FB"
            default: return "black"
        }
    }
    render() {
        return <div id="WhatAmIDoing" style={{color: this.getColor()}}>{this.getActivity()}</div>;
    }
}

class AnotherTeamIsPlaying extends React.Component<{ game: GameDTO }, {show:boolean}> {
    constructor(props:any) {
        super(props);
        this.state={show:false}
    }

    render() {

        return (
            <div>
                <div>  Hrá tím {this.props.game.getCurrentTeam().teamName} </div>
                <WhoAndWhatIsDoing game={this.props.game} />
                <Timer game={this.props.game} onComplete={()=>{}}/>
                {this.state.show ?
                    <div> <GameCard game={this.props.game}/>  <div><Button style={{...styles.button, ...styles.buttonBlue}}  onClick={()=>this.setState({show:false})}>Skryť kartičku</Button></div></div>
                    : <Button style={{...styles.button, ...styles.buttonBlue}}  onClick={()=>this.setState({show:true})}>Ukázať kartičku</Button> }
            </div>
        )
    }
}

class PlayingCelebritiesGame extends React.Component<{game:GameDTO}> {
    render() {
        let body;
        console.log("PlayingCelebritiesGame context", this.context)
        if(this.props.game.isPlayingTeamOfPlayer(this.context.uid)){
            if(this.props.game.isPlayingPlayer(this.context.uid)){
                body =  <Presenting game={this.props.game} />;
            } else {
                body = <Guessing game={this.props.game}/>;
            }
        } else {
            body =  <AnotherTeamIsPlaying game={this.props.game}/>;
        }
        return <div>
            <div>{this.props.game.round}. kolo</div>
            <div> je {this.props.game.getAllCelebrities().length} kartičietk, už sa v tomto kole uhádlo {this.props.game.celebritiesUsedInRound.length}, ostáva  {this.props.game.celebritiesAvailableInRound.length} </div>
            {body}
            <Results game={this.props.game}/>
        </div>
    }
}
PlayingCelebritiesGame.contextType = AppUserContext;

class Results extends React.Component<{ game: GameDTO }> {
    render() {
        return <div id="results"><ul >
            {this.props.game.teams.map((team)=><li>
                <div>tím {team.teamName} body: {team.solved}</div>
                <ul>
                    {team.players.map((player)=><li>{player.displayName}</li>)}
                </ul>
            </li>)}
        </ul></div>;
    }
}

class EndedCelebritiesGame extends React.Component<{game:GameDTO}> {
    render() {
        const winners: Team[] = this.props.game.getWinningTeams();

        return <div>
            {winners.length == 1 ? <div id="winner">vyhral tím  {winners[0].teamName}! </div>
                : <div id="winners">
                    Vyhrali tímy: <ul>{winners.map((team) => <li>{team.teamName}</li>)}</ul>
                </div>
            }
            <Results game={this.props.game}/>
        </div>
    }
}

class Game extends React.Component<{isOwner:boolean, room:RoomDTO }, {game:GameDTO| null}> {
    constructor(props:any) {
        super(props);
        this.handleNewGameDocument = this.handleNewGameDocument.bind(this)
        this.getGameContent = this.getGameContent.bind(this)
        this.state = {game:null}
    }

    handleNewGameDocument(doc:firebase.firestore.DocumentSnapshot){
        console.log("handleNewGameDocument",doc.exists && !!doc.data())
        console.log("handleNewGameDocument",!!doc.data())
        console.log("handleNewGameDocument",doc.exists )
        console.log("handleNewGameDocument",doc.data()??"neni su data")


        let newGame = null;
        if(doc.exists && !!doc.data()){
            if(this.state.game !=null){
                newGame = this.state.game;
                newGame.merge(doc.data())
            } else {
                newGame = new GameDTO(doc.data())
            }
        }

        this.setState(
            {
                game:newGame
            }
        )
    }

    componentDidMount() {
        if(this.props.room.gameId) {
            GameService.setOnGameChange(this.props.room.gameId, this.handleNewGameDocument);
        } else {
            //todo sth weird happened
        }
    }

    render() {
        return  <div id="game">
            { this.state.game == null ? "načítava sa hra..." : this.getGameContent(this.state.game) }
        </div>
    }

    getGameContent(game: GameDTO) {
        switch(game.celebritiesGamePhase){
            case "settings":
                return this.props.isOwner ? <SettingCelebritiesGame room={this.props.room} game={game}/> : <ViewSettingCelebritiesGame game={game}/>
            case "preparing":
                return <PreparingCelebritiesGame  isOwner={this.props.isOwner} game={game} appUser={this.context}/>
            case "playing":
                return  <PlayingCelebritiesGame game={game}/>
            case "ended":
                return  <EndedCelebritiesGame game={game}/>
            default: return "neočakavaný stav hry...";
        }
    }
}
Game.contextType = AppUserContext;



class MyRoom extends  React.Component<{user:AppUser}, {room:RoomDTO|null, inGame:boolean}> {

    constructor(props:any) {
        super(props);
        this.getPendingGuests=this.getPendingGuests.bind(this)
        this.getApprovedGuests=this.getApprovedGuests.bind(this)
        this.handleNewRoomDocument = this.handleNewRoomDocument.bind(this)
        this.state = {room:null, inGame:false}
    }

    handleNewRoomDocument(doc:firebase.firestore.DocumentSnapshot){
        this.setState(
            {
                room:  doc.exists && !!doc.data() ? new RoomDTO(doc.data()) : null
            }
        )
    }

    componentDidMount() {
        RoomService.setOnRoomChange(this.props.user.roomId, this.handleNewRoomDocument);
    }

    getRoomContent(room: RoomDTO) {
        if(room.gameId && this.state.inGame){
            return <div>
                <div id="gameInMyRoomControls">
                    <Button id="endGameButton" style={{...styles.button, ...styles.buttonRed}} onClick={async () => {
                        await GameService.deleteGame(room);
                        console.log("vymyzane...");
                    }}>ukončiť hru</Button>
                    <div id="returnToMyRoom">Hrá sa u vás v izbe <Button style={{...styles.button, ...styles.buttonGray}}  onClick={()=>{this.setState({inGame:false})}} >Prejsť do svojej izby</Button></div>
                </div>

                <Game room={room} isOwner={true} />

            </div>
        } else {
            return  <div>
                <h1 className="phaseTitle">Si u seba v izbe</h1>

                <div id="pendingGuests">{this.getPendingGuests(room)}</div>
                <div id="guestsInRoom">{this.getApprovedGuests(room)}</div>
                {!room.gameId &&
                <Button  style={{...styles.button, ...styles.buttonGreen}} onClick={()=>{this.setState({inGame:true});GameService.startGameInRoom(room.id,room)}} >Spustiť hru</Button>}
                {room.gameId && !this.state.inGame  && <Button  style={{...styles.button, ...styles.buttonGreen}} onClick={()=>{this.setState({inGame:true})}} >Vrátiť sa do hry</Button>}
            </div>

        }
    }

    private getPendingGuests(room: RoomDTO) {
        if(room.pendingGuests.length == 0){
            return <div>žiadny klopúci hostia....</div>
        } else {
            return (
                <div>
                    Klopúci hostia:
                    <ul>
                        {
                            room.pendingGuests.map((player)=>
                                    <li>{player.displayName} ({player.mail})
                                <Tooltip title="Pustiť do izby"><IconButton onClick={()=>UserService.approvePendingGuestInRoom(player, room.id)}><Icon>check</Icon></IconButton></Tooltip>
                                <Tooltip title="Vyhodiť z izby"><IconButton onClick={()=>UserService.removeUserFromRoom(player.uid, room.id)}><Icon>remove_circle_outline</Icon></IconButton></Tooltip>
                            </li>
                            )
                        }
                    </ul>
                </div>
            )
        }
    }
    private getApprovedGuests(room: RoomDTO) {
        if(room.guests.length == 0){
            return <div>žiadny hostia v izbe....</div>
        } else {
            return (
                <div>
                    Hostia v izbe:
                    <ul>
                        {
                            room.guests.map((player)=><li><img  className={"avatar"} src={player.photoUrl}/>{player.displayName} ({player.mail})   <Tooltip title="Vyhodiť z izby"><IconButton onClick={()=>UserService.removeUserFromRoom(player.uid, room.id)}><Icon>remove_circle_outline</Icon></IconButton></Tooltip></li>)
                        }
                    </ul>
                </div>
            )
        }
    }

    render() {
        return (
            <div>
                { this.state.room == null ? "načítava sa izba" : this.getRoomContent(this.state.room) }
            </div>
        );
    }
}

class ForeignRoom extends  React.Component<{user:AppUser}, {room:RoomDTO|null}> {
    constructor(props:any) {
        super(props);
        this.handleNewRoomDocument = this.handleNewRoomDocument.bind(this)
        this.state = {room:null}
    }

    handleNewRoomDocument(doc:firebase.firestore.DocumentSnapshot){
        let newRoom = null;
        if(doc.exists && !!doc.data()){
            if(this.state.room !=null){
                newRoom = this.state.room;
                newRoom.merge(doc.data())
            } else {
                newRoom = new RoomDTO(doc.data())
            }
        }
        this.setState(
            {
                room: newRoom
            }
        )
    }

    componentDidMount() {
        RoomService.setOnRoomChange(this.props.user.roomId, this.handleNewRoomDocument);
    }

    render() {
        return (
            <div>
                { this.state.room == null ? "načítava sa izba" : this.getRoomContent(this.state.room) }
            </div>
        );
    }

    getRoomHeader(room:RoomDTO){
        return (
            <div id="ForeignRoomHeader">
                <div className="roomOwnerDisplayName">Izba používateľa {room.roomOwner.displayName} <img className={"avatar"} src={room.roomOwner.photoUrl}/></div>
                <div className="roomOwnerEmail">{room.roomOwner.mail}</div>
                <div className="roomId">(id izby: {room.id})</div>
            </div>
        )
    }

    getRoomContent(room: RoomDTO) {
        console.log("getRoomContent room:", room)
        if(room.gameId!=null && room.isApproved(this.context)){
            return <Game room={room} isOwner={false} />
        } else {
            return (
                <div>
                    {this.getRoomHeader(room)}
                    {this.getWhatIsNextStep(room)}
                    <div id="gestsAndPendingTests">
                        {this.getPendingGuests(room)}
                        {this.getApprovedGuests(room)}
                    </div>
                </div>

            )


        }
    }


    private getWhatIsNextStep(room: RoomDTO) {
        if(room.pendingGuests.some((player)=> player.uid == this.context.uid)){
            return <div>Počkajte kým vás hosť pustí do izby</div>
        } else {
            return (
                <div>
                    Počkajte kým hosť spustí hru...
                </div>
            )
        }
    }

    private getPendingGuests(room: RoomDTO) {
        if(room.pendingGuests.length == 0){
            return <div>žiadny klopúci hostia....</div>
        } else {
            return (
                <div>
                    Klopúci hostia:
                    <ul>
                        {
                            room.pendingGuests.map((player)=><li>{player.displayName} ({player.mail})</li>)
                        }
                    </ul>
                </div>
            )
        }
    }
    private getApprovedGuests(room: RoomDTO) {
        if(room.guests.length == 0){
            return <div>žiadny hostia v izbe....</div>
        } else {
            return (
                <div>
                    Hostia v izbe:
                    <ul>
                        {
                            room.guests.map((player)=><li><img  className={"avatar"} src={player.photoUrl}/>{player.displayName} ({player.mail})</li>)
                        }
                    </ul>
                </div>
            )
        }
    }
}
ForeignRoom.contextType = AppUserContext;

class LoggedInScreen extends React.Component<{userCredential:firebase.auth.UserCredential, onLoggout:Function}, {user:AppUser|null, infoOpen:boolean}>{
    constructor(props:any) {
        super(props);
        this.state = {user:null, infoOpen:false}
        this.handleNewUserDocument = this.handleNewUserDocument.bind(this)

        this.signOut = this.signOut.bind(this)

    }

    async signOut() {
        await firebase.auth().signOut().then(()=>this.setState({user:null}));
        this.props.onLoggout();
    }



    handleNewUserDocument(doc:firebase.firestore.DocumentSnapshot){
        let newUser:AppUser|null = null;
        if(doc.exists && !!doc.data()){
            if(this.state.user !=null){
                newUser = this.state.user;
                newUser.merge(doc.data())
            } else {
                newUser = new AppUser(doc.data())
            }
        }
        console.log("setting new user", newUser)
        this.setState(
            {
                user:newUser
            }
        )
    }

    componentDidMount() {
        if(this.props.userCredential.user) {
            UserService.setOnUserChange(this.props.userCredential.user.uid, this.handleNewUserDocument);
        }
    }

    getScreenContent(){
        console.log("getScreenContent",this.state.user)
        if(this.state.user == null){
            return "načítavaju sa informácie o užívatelovi...";
        } else {
            if(this.state.user.roomId == null){
                return  <HomeLoggedIn user={this.state.user}/>
            } else {
                if(this.state.user.roomId == this.state.user.uid){
                    return<MyRoom user={this.state.user} />
                } else {
                    return <ForeignRoom user={this.state.user} />
                }
            }
        }
    }

    handleClose = () => {
        this.setState({infoOpen:false})
    }

    render() {
        return (
            <>
            <AppUserContext.Provider value={this.state.user? this.state.user:null}>
                <header className="App-header">
                    <Button  style={{...styles.button, ...styles.buttonBlue}} id="infoButton"  onClick={()=>this.setState({infoOpen:true})}> Info </Button>

                    <span className="title">Zahrajme sa v0.2.0</span>
                    {/*{  this.state.user != null && <span id="loggedinUserNameInHeader"> {this.state.user.displayName}</span>}*/}
                    <div className="actions">{  this.state.user != null && <Button  style={{...styles.button}} id="logoutButton"  onClick={this.signOut}>Odhlásiť sa</Button> }
                        {  this.state.user != null && this.state.user.roomId &&
                        <Button  style={{...styles.button, ...styles.buttonBlue}} id="leaveRoom"  onClick={()=>UserService.removeUserFromRoom(this.state.user?.uid,this.state.user?.roomId)}>odísť z izby </Button>
                        }
                    </div>
                </header>
                <div>
                    {this.getScreenContent()}
                </div>
            </AppUserContext.Provider>
                <Dialog
                    open={this.state.infoOpen}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth={true}
                    maxWidth="lg"
                >
                    <DialogTitle id="alert-dialog-title">{"Informácie"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Info />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose} color="primary" autoFocus>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }

}
class App extends React.Component<any,{user:firebase.auth.UserCredential|null, loading:boolean,error:any}>{
    constructor(props:any) {

        super(props);
        this.state = {
            user: null,
            loading:false,
            error:null,
        };


    }

    render() {
        return (
            <FirebaseUserContext.Provider value={this.state.user? this.state.user.user:null}>
                <div className="App">
                    {!this.state.loading && !!this.state.error && <div>Nastala chyba, skús sa prihlásiť znovu...</div>}
                    {
                        this.state.loading ? <div>Prebieha prihlasovanie...</div>
                            : this.state.user == null
                            ? <LoginScreen
                                onLogin={(userCredential:firebase.auth.UserCredential)=>this.setState({user:userCredential, loading:false, error:null})}
                                onLoginFailed={(errorMessage:any)=>{this.setState({loading:false, error:errorMessage})}}
                                onStartLoggingIn={()=>{this.setState({loading:true})}}
                            />
                            : <LoggedInScreen userCredential={this.state.user} onLoggout={()=>this.setState({user:null, loading:false, error:null})}/>
                    }
                </div>
            </FirebaseUserContext.Provider>
        );
    }
}

const styles: Record<'button' | 'buttonBlue' | 'buttonGreen' | 'buttonOrange'| 'buttonGray'| 'buttonRed', React.CSSProperties> = {
    button: {
        background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
        borderRadius: 3,
        border: 0,
        color: 'white',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
        //display: "inline",
        //float:"right",
    },
    buttonBlue: {
        background: 'linear-gradient(45deg, #2196f3 30%, #21cbf3 90%)',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',
    },
    buttonGreen: {
        background: 'linear-gradient(315deg, #00b712 0%, #5aff15 74%)',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',
    },
    buttonOrange: {
        background: 'linear-gradient(315deg, #ff4e00 0%, #ec9f05 74%)',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',
    },
    buttonGray: {
        background: 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',
        color:"black",
    },
    buttonRed: {
        background: 'linear-gradient(315deg, #ED213A 0%, #93291E 74%)',
        boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .30)',
    },
};

export default App;
